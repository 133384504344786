.products-container h2 .react-tooltip {
	width: fit-content !important;
	z-index: 99;
}
.products-container h2 .react-tooltip p {
	font-size: 22px;
}
.products-container h2 svg {
	margin-top: 7px;
	margin-left: 10px;
}
.products-container .products-list .product-box {
	background: #fff;
	border: 1px solid #dee2e6;
	cursor: pointer;
}
.products-container .products-list .product-box a {
	text-decoration: none;
}
.products-container .products-list .product-box img {
	width: 120px;
	height: 90px;
	min-height: 90px;
	max-height: 90px;
	object-fit: cover;
}
.products-container .products-list .product-box span {
	color: #00379e;
	top: 2px;
	left: 8px;
	font-size: 12px;
	font-weight: 700;
}
.products-container .products-list .product-box p {
	font-size: 12px;
	font-weight: 700;
	color: #212529;
}
.products-container .products-list .product-box h5 {
	font-size: 20px;
	color: #212529;
}
.products-container .products-list .product-box p.condition {
	color: #00f;
	font-size: 13px;
	min-height: 32px;
}
.products-container .products-list .product-box .compare label {
	font-size: 12px;
	color: #212529;
}
.products-container .products-list .product-box .compare input {
	border-radius: 0.25em;
	background-color: #fff;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, .25);
	height: 1em;
	margin-top: 0.25em;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	vertical-align: top;
	width: 1em;
}
.selected-products {
	background: #000000bf;
	padding: 8px 0;
	bottom: 0;
	position: fixed;
	width: 100%;
	left: 0;
	z-index: 9;
}
.selected-products .product-list {
	gap: 10px;
	overflow-x: scroll;
	min-width: 300px;
}
.selected-products .product-list::-webkit-scrollbar {
	width: 20px;
	height: 6px;
}
.selected-products .product-list::-webkit-scrollbar-track {
	border-radius: 10px;
}
.selected-products .product-list::-webkit-scrollbar-thumb {
	background: #bfbfbf;
	border-radius: 10px;
}
.selected-products .selected-box {
	border-radius: 5px;
	position: relative;
	min-width: 250px;
}
.selected-products .selected-box span {
	top: 10px;
	right: 40px;
	font-size: 10px;
	color: #00379e;
}
.selected-products .selected-box p {
	font-size: 12px;
}
.selected-products .selected-box p.discount {
	color: #00f;
}
.selected-products .selected-box h5 {
	font-size: 14px;
}
.selected-products .selected-box .close {
	background: transparent;
	border: 1px solid #000;
	border-radius: 50px;
	height: 25px;
	line-height: 18px;
	padding: 0;
	position: absolute;
	right: 5px;
	text-align: center;
	top: 3px;
	width: 25px;
}
@media only screen and (min-width: 768px) {
	.products-container .products-list .product-box span {
		font-size: 14px;
		line-height: 16px;
		left: unset;
		right: 5px;
		top: 4px;
   }
	.products-container .products-list .product-box p {
		font-size: 14px;
		line-height: 16px;
   }
	.products-container .products-list .product-box p:nth-child(1) {
		min-height: 48px;
   }
	.products-container .products-list .product-box h5 {
		font-size: 22px;
		/* line-height: 10px; */
   }
	.selected-products .selected-box .product-details {
		min-width: 175px;
   }
}
@media only screen and (min-width: 992px) {
	.products-container .products-list .product-box p:nth-child(1) {
		min-height: unset;
   }
}
@media only screen and (min-width: 1200px) {
	.products-container .products-list .product-box p:nth-child(1) {
		min-height: 32px;
   }
}
@media only screen and (min-width: 1400px) {
	.products-container .products-list .product-box p:nth-child(1) {
		min-height: unset;
   }
}
