.tabs .tabs-banner {
    background: #23364d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.tabs .tabs-banner .tabs-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.tabs .tabs-banner .tabs-main .tab-heading {
    background: #fff;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    color: #000;
    margin-bottom: 2rem;
    border: 2px solid transparent;
    cursor: pointer;
}
.tabs .tabs-banner .tabs-main .tab-heading img {
    width: 60px;
    height: 60px;
    background: #000;
}
.tabs .tabs-banner .tabs-main .tab-heading .tab-content {
    width: calc(100% - 80px);
    word-wrap: break-word;
}
.tabs .tabs-banner .tabs-main .tab-heading .tab-content h6 {
    font-size: 16px;
}
.tabs .tabs-banner .tabs-main .tab-heading .tab-content p {
    font-size: 14px;
    margin-bottom: 0;
}
.tab-active {
    border: 2px solid red !important;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container {
    order: 2;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item {
    border: none;
    padding-block: 1rem;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-header button {
    padding: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    font-size: 18px;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 5px;
}
.tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body a:last-child {
    margin-bottom: 0;
}
.tabs-accordion-main .tabs-accordion-container .tab-content {
    order: 1;
}
p.help-topic-active{
    background-color: #d5d5d5;
}

p.main-heading-help {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    max-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    /* overflow: hidden; */
}

@media screen and (min-width: 768px) {
   
    .tabs .tabs-banner .tabs-main .tab-heading {
        width: 48%;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container {
        order: 1;
        border-right: 1px solid #bfbfbf;
        padding-right: 2rem;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item {
        border: none;
        padding-block: 1rem;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-header button {
        padding: 0;
        background: none;
        outline: none;
        box-shadow: none;
        border: none;
        font-size: 18px;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 1rem;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body a {
        color: #000;
        text-decoration: none;
        font-size: 16px;
        margin-bottom: 5px;
   }
    .tabs-accordion-main .tabs-accordion-container .accordion-container .accordion .accordion-item .accordion-body a:last-child {
        margin-bottom: 0;
   }
    .tabs-accordion-main .tabs-accordion-container .tab-content {
        order: 2;
   }
}
@media screen and (min-width: 1200px) {
  
    .tabs .tabs-banner .tabs-main .tab-heading {
        width: 23%;
   }
}

@media screen and (min-width: 999px) and (max-width: 1199px) {
    .help-search {
      width: 85% !important;
    }
  }
  