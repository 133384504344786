.market .slick-slide img {
    object-fit: cover;
    object-position: top;
    aspect-ratio: 10 / 3 !important;
}
.market .slick-arrow::before {
    color: #000;
    font-size: 40px;
}
.market .slick-prev {
    left: -55px;
}
.market .slick-next{
    right: -35px;
}

