.banner .banner-container .filter-box {
    cursor: pointer;
}
.banner .banner-container .filter-box img {
    /* aspect-ratio: 2.5; */
    object-fit: fill;
    
}
.market.cs_ratio img {
    aspect-ratio: 10 / 3;
}
.banner .banner-container .filter-box ul li {
    font-size:20.8px;
    background: #fff;
    padding: 8px 8px 1px;
    border-radius: 3px;
    font-weight:bold;
}
.filter-img p{
    font-size:20.8px;
    font-weight:600;
}