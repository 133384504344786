
.btn-box {
    position: relative;
}
.custom-bt-tooltip {
    position: absolute;
    background: #00000050;
    bottom: -5px;
    right: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    display: none;
    transition: all 2s ease;
}
.custom-bt-tooltip p{
    color: #fff;
    white-space: nowrap;
    margin: 0;
}
.btn-box:hover .custom-bt-tooltip{
    display: block;
    transition: all .5s;
}
.button-section {
    height:100vh;
    position: fixed;
    right: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    z-index: 999;
}
.button-section .btn-box  button{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0;
    outline: 0;
}
.button-section .btn-box button.inactive {
    background: #fff;
  }
  .button-section button.active {
    background: #8e8e8edd;
  }
.secs{
    height: 100vh;
    display: flex;
    align-items: center;
}
.secs h2{
    font-size: 40px;
}
.secs p{
    font-size: 21px;
    font-weight: 500;
}
.scroll-main{
    overflow-y: scroll;
    height: calc(100vh - 122px);
}
.scroll-main::-webkit-scrollbar { 
    width: 0 !important;
    display: none; 
  }
#section1 {
    background: url(../../images/bg-1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    
}
#section2 {
    background: url(../../images/b1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section2 .custom-text{
    margin-left: -70px;
}
#section3 {
    background: linear-gradient(to top, #00000080, #00000080), url(../../images/b2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section4 {
    background: url(../../images/b3.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section5 {
    background: url(../../images/b4.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section6 {
    background: url(../../images/b5.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section7 {
    background: url(../../images/b7.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#section8 {
    background:linear-gradient(to top, #00000080, #00000080), url(../../images/b8.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.mobile-scroll{
    display: none;
}
@media screen and (max-width:991px){
    .mobile-scroll{
        display: block;
    }
    .desktop-slider{
        display: none;
    }
    .secs{
        margin-bottom: 50px;
        height: 100%;
    }
    #section1 , #section2 , #section3, #section4, #section5, #section6, #section7, #section8{
        background: none;
    }
    .secs img{
        margin-bottom: 15px;
        width: 100%;
    }
    #section1{
        margin-top: 30px;
    }
    
    .secs p {
        color: #000 !important;
    }
    .secs h2 {
        color: #000 !important;
    }
}
@media screen and (max-width:575px){
    .button-section {
        right: 15px;
    }
    .secs p {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .secs h2 {
        font-size: 30px;
        margin-bottom: 5px !important;
    }
    .secs{
        margin-bottom: 30px;
    }
}