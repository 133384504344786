.search input{
    border-radius: 5px;
    border:1px solid #dee2e6;
    background:#fff;
}
.search input::placeholder{
    color:#333;
}
.news-grid img{
    border-radius:10px;
}
.News-section .active span.page-link {
    background: black;
    border: none;
    color:#fff;
    border-radius: 5px;
}
.News-section .page-link{
    border: none;
    color:#000;
}
.New-categoryy {
    background: #7a4cd524;
    border-radius: 10px;
    width: 100%;
    max-width: 200px;
    margin : 0 auto;
    color: #411ef7;

}
p.New-category {
    background: #7a4cd524;
    border-radius: 10px;
    width: 100%;
    max-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #411ef7;
}
.grid-list{
    border-radius:10px;
    box-shadow:0px 0px 10px #ccc;
}
.all-post-btn{
    background:#fff;
    border:1px solid #000;
    border-radius: 5px;
    color:#000;
    font-weight:500;
    margin: 0 auto;
    text-align: center;
    display: flex;
    padding: 12px 20px;
}